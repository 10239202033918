import styled from "styled-components";

interface StyledSectionProps {
  bgColor?: string;
}

interface StyledTitleProps {
  size?: string;
  color?: string;
  sizeDesktop?: string;
}

interface StyledTitleHelpProps {
  size?: string;
  color?: string;
  sizeDesktop?: string;
}

interface StyledProps {
  bgColor?: string;
  backgroundImage?: string;
}

interface StyledContainerParagraphProps {
  isActive: boolean;
}

interface StyledParagraphProps {
  color?: string;
  size?: string;
}


export const StyledSection = styled.section<StyledSectionProps>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 52px;
  width: 100%;
`;

export const StyledSeccion = styled.div<StyledProps>`
  background-color: ${(props) => props?.bgColor ?? "#fff"};
`;

export const StyledSectionContent = styled.div<StyledProps>`
  background-image: url(${(props) => props?.backgroundImage});
`;


export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  padding: 16px 16px 16px 16px;
  align-items: center;
`;

export const StyledTitle = styled.h3<StyledTitleProps>`
  font-size: ${(props) => props?.size ?? "14px"};
  color: ${(props) => (props?.color ? props?.color : "#fff")};
  transition: all 0.4s ease;
`;

export const StyledTitleHelp = styled.h3<StyledTitleHelpProps>`
  color: ${(props) => (props?.color ? props?.color : "#fff")};
`;

export const StyledImg = styled.img`
  cursor: pointer;
`;

export const StyledButton = styled.button`
  border: none;
  background-color: transparent;
`;

export const StyledContainerParagraph = styled.div<StyledContainerParagraphProps>`
  display: flex;
  transition: ${({ isActive }) =>
    isActive ? "max-height 1s ease-in-out " : "max-height 0.3s ease-in-out"};
  max-height: ${({ isActive }) => (isActive ? "700px" : "0")};
  overflow: hidden;
`;

export const StyledParagraph = styled.p<StyledParagraphProps>`
  color: ${(props) => props?.color ?? "#fff"};
  font-size: ${(props) => props?.size ?? "18px"};
`;