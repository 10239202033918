import styled from 'styled-components';

// Define una interfaz para las propiedades de los componentes estilizados
interface StyledProps {
  fontSize?: string;
  fontColor?: string;
  bgColor?: string;
}

export const StyledTitle = styled.h2<StyledProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '64px')};
  color: ${(props) => (props.fontColor ? props.fontColor : '#fff')};
`;

export const StyledSubTitle = styled.p<StyledProps>`
  color: ${(props) => (props.fontColor ? props.fontColor : '#fff')};
`;

export const StyledSectionContent = styled.div<StyledProps>`
  background: ${(props) => (props.bgColor ? props.bgColor : '#fff')};
`;