import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { domain_country, getDomain } from './config/domain';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './scss/App.scss';
import Callback from './components/CallBack/callback';
import PageError from './components/error/error';
import FooterTerms from './components/footer-terms/footer-terms';
import { Landing } from './components/home/landing';
import LandingUserWithSession from './components/home/LandingUserWithoutSession';
import { Legal } from './components/legal/legal';
import { Cards } from './components/Cards/cards';
import LoadSpinner from './components/load-spinner/load-spinner';
import LateralMenu from './components/menu/menu';
import { Profile } from './components/profile/profile';
import { Default } from './components/default-section/default';
import AboutUs from './components/aboutUs';
import Careers from './components/careers';
import NewsDetail from './components/newsDetail';
import {
  AddVideoContest,
  ListVideoContest,
  PreviewVideoContest,
} from './components/contest';
import { Loading } from './components/security/loading/loading';
import { Dashboard } from './components/security/dashboard/dashboard';
import { Form } from './components/security/form/form';
import { Ranking } from './components/security/ranking/ranking';
import { Game } from './components/security/game/game';
import { OnBoarding } from './components/security/onboarding/onboarding';
import { Correct } from './components/security/result/correct';
import { InCorrect } from './components/security/result/incorrect';
import { Level } from './components/security/result/level';
import { Endlives } from './components/security/result/endlives';
import { Uplevel } from './components/security/result/uplevel';
import { Unlock } from './components/security/result/unlock';
import { Endgame } from './components/security/result/endgame';
import { Faqs } from './components/security/acordeon/Acordeon';
import { Share } from './components/security/share/share';
import { ShareSend } from './components/security/share/shareSend';

import ProtectedRoute from './components/security/navigation/NavigationControl';

const App = () => {
  const [accept, setAccept] = useState('-1');
  const [domain] = useState(domain_country(window.location.hostname + ''));
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const root = () => {
    setDomainData(getDomain(window.location.hostname + ''));
    document.documentElement.style.setProperty(
      '--font-family-mon',
      domain.fonts,
    );
    document.title = domainData.name || '';
  };
  useEffect(() => {
    root();
    if (localStorage.getItem('code_verifier')) {
      if (localStorage.getItem('localToken')) {
        setAccept('1');
        localStorage.setItem('accept-terms', '1');
      }
    }
  }, []);

  if (localStorage.getItem('code_verifier')) {
    if (accept === '0') {
      return (
        <div className={domain.color}>
          <BrowserRouter forceRefresh>
            <Route path="/auth/callback" exact component={Callback} />
            <Route
              path="*"
              component={() => (
                <Legal
                  acceptTerms={(a: string) => {
                    setAccept(a);
                  }}
                />
              )}
            />
          </BrowserRouter>
          <FooterTerms />
        </div>
      );
    } else if (accept === '1') {
      return (
        <div className={domain.color}>
          <BrowserRouter>
            <LateralMenu />
            <Switch>
              <Route
                path="/Memons-des-ynch-Helld-the-these-much-malast-harm"
                exact
                component={PageError}
              />
              <Route path="/landing" exact component={Landing} />
              <Route path="/about-us" exact component={AboutUs} />
              <Route path="/careers" exact component={Careers} />
              <Route path="/news/:newsId" exact component={NewsDetail} />
              <Route path="/auth/callback" exact component={Callback} />
              <Route
                path="/embajador-mision"
                exact
                component={ListVideoContest}
              />
              <Route
                path="/embajador-mision/carga"
                exact
                component={AddVideoContest}
              />
              <Route
                path="/embajador-mision/:id"
                exact
                component={PreviewVideoContest}
              />
              <Route path="/profile" exact component={Profile} />
              <Route path="/legal" exact component={Legal} />
              <Route path="/cartas-de-cultura" exact component={Cards} />
              <Route path="/securitymatch" exact component={Loading} />
              <Route path="/securitymatch/start-form" exact component={Form} />
              <ProtectedRoute
                path="/securitymatch/user-zone"
                exact
                component={Dashboard}
              />
              <ProtectedRoute
                path="/securitymatch/ranking"
                exact
                component={Ranking}
              />

              <ProtectedRoute
                path="/securitymatch/pregunta/:id"
                exact
                component={Game}
              />
              <ProtectedRoute
                path="/securitymatch/respuesta-correcta"
                exact
                component={Correct}
              />
              <ProtectedRoute
                path="/securitymatch/respuesta-incorrecta"
                exact
                component={InCorrect}
              />
              <ProtectedRoute
                path="/securitymatch/onboarding"
                exact
                component={OnBoarding}
              />
              <ProtectedRoute
                path="/securitymatch/nivel"
                exact
                component={Level}
              />
              <ProtectedRoute
                path="/securitymatch/nivel-completo"
                exact
                component={Uplevel}
              />
              <ProtectedRoute
                path="/securitymatch/personaje-desbloqueado"
                exact
                component={Unlock}
              />
              <ProtectedRoute
                path="/securitymatch/fin-de-vidas"
                exact
                component={Endlives}
              />
              <ProtectedRoute
                path="/securitymatch/fin-de-juego"
                exact
                component={Endgame}
              />
              <ProtectedRoute
                path="/securitymatch/preguntas-frecuentes"
                exact
                component={Faqs}
              />
              <ProtectedRoute
                path="/securitymatch/referidos-form"
                exact
                component={Share}
              />
              <ProtectedRoute
                path="/securitymatch/share-send"
                exact
                component={ShareSend}
              />
              <Route path="/:newsId" exact component={NewsDetail} />
              <Route path="/" exact component={Landing} />
              <Route path="*" exact component={PageError} />
            </Switch>
          </BrowserRouter>
          <FooterTerms />
        </div>
      );
    } else {
      return (
        <div className={domain.color}>
          <BrowserRouter>
            <Route path="/auth/callback" exact component={Callback} />
            <Route path="*" exact component={LoadSpinner} />
          </BrowserRouter>
          <FooterTerms />
        </div>
      );
    }
  } else {
    return (
      <div className={domain.color}>
        <LandingUserWithSession />

        {/*  <BrowserRouter>
          <Switch>
            <Route path="/securitymatch" exact component={Loading} />
            <Route path="/securitymatch/start-form" exact component={Form} />
            <ProtectedRoute
              path="/securitymatch/user-zone"
              exact
              component={Dashboard}
            />
            <ProtectedRoute
              path="/securitymatch/ranking"
              exact
              component={Ranking}
            />

            <ProtectedRoute
              path="/securitymatch/pregunta/:id"
              exact
              component={Game}
            />
            <ProtectedRoute
              path="/securitymatch/respuesta-correcta"
              exact
              component={Correct}
            />
            <ProtectedRoute
              path="/securitymatch/respuesta-incorrecta"
              exact
              component={InCorrect}
            />
            <ProtectedRoute
              path="/securitymatch/onboarding"
              exact
              component={OnBoarding}
            />
            <ProtectedRoute
              path="/securitymatch/nivel"
              exact
              component={Level}
            />
            <ProtectedRoute
              path="/securitymatch/nivel-completo"
              exact
              component={Uplevel}
            />
            <ProtectedRoute
              path="/securitymatch/personaje-desbloqueado"
              exact
              component={Unlock}
            />
            <ProtectedRoute
              path="/securitymatch/fin-de-vidas"
              exact
              component={Endlives}
            />
            <ProtectedRoute
              path="/securitymatch/fin-de-juego"
              exact
              component={Endgame}
            />
            <ProtectedRoute
              path="/securitymatch/preguntas-frecuentes"
              exact
              component={Faqs}
            />
            <ProtectedRoute
              path="/securitymatch/referidos-form"
              exact
              component={Share}
            />
            <ProtectedRoute
              path="/securitymatch/share-send"
              exact
              component={ShareSend}
            />
          </Switch>
        </BrowserRouter> */}
        <FooterTerms content={domainData} />
      </div>
    );
  }
};

export default App;
