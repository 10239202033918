import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  FormSubmit,
  Zonadeusurio,
  FormSubmitFull,
  getCachedZonadeusurio,
  clearZonadeusurioCachedData,
} from '../../../api/security/index';
import { useResponsive } from '../../../helpers/responsive/useResponsive';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import {
  gtmSecurityLoginSuccess,
  gtmSecurityLoginError,
  gtmSecurityArrowleft,
  gtmSecurityArrowRight,
} from '../../../tools/datalayers';

// Inicializa los módulos de Swiper

import { StyledTitle } from './form.styles';

SwiperCore.use([Pagination, Navigation]);

interface Avatar {
  image: { url: string };
  id: number;
  avatarName: string;
}

interface FieldAvatar {
  avatar: Avatar;
}

interface ItemsStartForm {
  backgroundImageDesk: { url: string };
  titleColor: string;
  titleSize: string;
  titleForm: string;
  submitEndPoint: string;
  foldsForm: { __component: string; fieldAvatar?: FieldAvatar[] }[];
}

interface ItemForm {
  itemsStartForm: ItemsStartForm;
}

interface StartForm {
  itemForm: ItemForm[];
  submitEndPoint?: string;
}

interface FoldsForm {
  __component?: string;
}

interface Fold {
  startForm: StartForm;
}

interface Content {
  folds: Fold[];
}

interface FetchedData {
  content: Content[];
}

export const Form = () => {
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [avatars, setAvatars] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [fetchedData, setFetchedData] = useState<FetchedData | null>(null);
  const [isNameValid, setIsNameValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const base = process.env.REACT_APP_API_BASE_URL;
  const formRef = useRef<HTMLDivElement>(null);
  const { width } = useResponsive();

  useEffect(() => {
    if (
      fetchedData?.content[0]?.folds[0]?.startForm?.itemForm[1]?.itemsStartForm
        ?.foldsForm[0]?.fieldAvatar
    ) {
      setAvatars(
        fetchedData.content[0].folds[0].startForm.itemForm[1].itemsStartForm
          .foldsForm[0].fieldAvatar,
      );
    }
  }, [fetchedData]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        event.stopPropagation();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleAvatarSelection = useCallback(
    (value: string, event: React.MouseEvent) => {
      event.stopPropagation();
      setSelectedOption((prevSelected) =>
        prevSelected === value ? null : value,
      );
    },
    [],
  );

  useEffect(() => {
    const fetchData = async () => {
      const tokenJwt = localStorage.getItem('myAccessToken') || '';
      const myEndpoint = localStorage.getItem('myEndpoint') || '';

      if (!tokenJwt || !myEndpoint) {
        console.error('Faltan el token o el endpoint');
        setIsLoading(false);
        return;
      }

      // Intentar obtener datos en caché primero
      const cachedData = getCachedZonadeusurio();
      if (cachedData) {
        setFetchedData(cachedData);
        setIsLoading(false);
        return;
      }

      // Si no hay datos en caché, hacer la solicitud al servidor
      try {
        const data = await Zonadeusurio(myEndpoint, tokenJwt);
        if (data) {
          setFetchedData(data);
        }
      } catch (error) {
        console.error('Error de servicio', error);
        clearZonadeusurioCachedData();
      } finally {
        setIsLoading(false);
        clearZonadeusurioCachedData();
      }
    };

    fetchData();
  }, []);

  const validateNickname = (nickname: string) => {
    return nickname.length > 0 && nickname.length <= 10;
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
    setIsNameValid(validateNickname(newName));
    setError(null); // Limpiamos el error al cambiar el nickname
  };

  const LoadingSpinner = () => (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <img src="/loader_1.gif" alt="Loading..." />
    </div>
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleSubmitForm = async (
    endpoint: string,
    inputValue: string,
    component: string,
  ) => {
    const tokenJwt = localStorage.getItem('myAccessToken') || '';

    FormSubmit(endpoint, tokenJwt, inputValue, component)
      .then(({ data }) => {
        gtmSecurityLoginSuccess();
        if (data.next) {
          handleNext();
        }
        setError('');
      })
      .catch(({ response: { data } }) => {
        gtmSecurityLoginError();
        const { error } = data;
        const message =
          error.details?.arrayError?.['folds-form.nick-name']?.[1] ||
          'Debe ingresar los datos solicitados correctamente.';
        setError(message);
      });
  };

  const handleSubmit = async (
    endpoint: string,
    inputValue: string,
    component: string,
    inputValueTwo: string,
    componentTwo: string,
  ) => {
    //alert( combinedData + 'Datos enviados exitosamente');
    const tokenJwt = localStorage.getItem('myAccessToken') || '';

    FormSubmitFull(
      endpoint,
      tokenJwt,
      inputValue,
      component,
      inputValueTwo,
      componentTwo,
    )
      .then(({ data }) => {
        setError('');
        if (data?.endpoint) {
          const fullUrl = `${window.location.origin}/${data?.endpoint}`;
          window.open(fullUrl, '_self');
        }
      })
      .catch((error) => {
        console.error('Error de servicio', error);
      });
  };

  const getActiveAvatarImage = (avatarName: string) => {
    switch (avatarName.toLowerCase()) {
      case 'locky':
        return '/LockyAct.png';
      case 'cryptix':
        return '/CriptixAct.png';
      case 'vortex':
        return '/VortexAct.png';
      default:
        return '';
    }
  };

  const handlePrevClick = (swiper: SwiperClass) => {
    const currentSlide = swiper.realIndex;
    const slideName: string = avatars[currentSlide]?.avatar.avatarName || '';
    gtmSecurityArrowleft(slideName);
  };

  const handleNextClick = (swiper: SwiperClass) => {
    const currentSlide = swiper.realIndex;
    const slideName: string = avatars[currentSlide]?.avatar.avatarName || '';
    gtmSecurityArrowRight(slideName);
  };

  return (
    <>
      <div className="page user-form">
        <div className="content__bg">
          <img
            className="content__bg-main"
            src={`${base}${fetchedData?.content[0]?.folds[0]?.startForm?.itemForm[0]?.itemsStartForm?.backgroundImageDesk?.url}`}
            alt=""
          />
          <div className="content__bg-items">
            {/* <img className={`item-hand hand--1 ${fadeOut ? 'fade-out-hand' : ''}`} src={imgthree?.src} alt="" />
            <img className={`item-hand hand--2 ${fadeOut ? 'fade-out-hand' : ''}`} src={imgseven?.src} alt="" />
            <img className={`item-hand hand--3 ${fadeOut ? 'fade-out-hand' : ''}`} src={imgsix?.src} alt="" />
            <img className={`item-hand hand--4 ${fadeOut ? 'fade-out-hand' : ''}`} src={imgfour?.src} alt="" /> */}
          </div>
        </div>
        <div className="page__container-form">
          <div className="content__form">
            {step === 1 && (
              <div className="content__form-container">
                <StyledTitle
                  className="title__main"
                  fontColor={
                    fetchedData?.content[0]?.folds[0]?.startForm?.itemForm[0]
                      ?.itemsStartForm?.titleColor
                  }
                  fontSize={
                    width <= 991
                      ? '56px'
                      : fetchedData?.content[0]?.folds[0]?.startForm
                          ?.itemForm[0]?.itemsStartForm?.titleSize
                  }
                >
                  {
                    fetchedData?.content[0]?.folds[0]?.startForm?.itemForm[0]
                      ?.itemsStartForm?.titleForm
                  }
                </StyledTitle>
                <div className={`content__input ${error ? 'error-input' : ''}`}>
                  <div className="nickname-input-container">
                    <input
                      type="text"
                      value={name}
                      onChange={handleNameChange}
                      placeholder="Escribe tu nickname"
                      maxLength={10}
                    />
                    {name && (
                      <img
                        src={
                          !error && isNameValid
                            ? '/IconGreen.svg'
                            : '/IconRed.svg'
                        }
                        alt={
                          !error && isNameValid
                            ? 'Nickname válido'
                            : 'Nickname inválido'
                        }
                        className="nickname-validation-icon"
                      />
                    )}
                  </div>
                  <span className="text-info-form">10 Caracteres máx.</span>
                  {error && <p className="error-form">{error}</p>}
                </div>
                <div className="content__button">
                  <button
                    onClick={() => {
                      const submitEndPoint = fetchedData?.content[0]?.folds[0]
                        ?.startForm?.itemForm[0]?.itemsStartForm?.submitEndPoint
                        ? fetchedData?.content[0]?.folds[0]?.startForm
                            ?.itemForm[0]?.itemsStartForm?.submitEndPoint
                        : '';
                      const component = fetchedData?.content[0]?.folds[0]
                        ?.startForm?.itemForm[0]?.itemsStartForm?.foldsForm[0]
                        ?.__component
                        ? fetchedData?.content[0]?.folds[0]?.startForm
                            ?.itemForm[0]?.itemsStartForm?.foldsForm[0]
                            ?.__component
                        : '';
                      handleSubmitForm(submitEndPoint, name, component);
                    }}
                    disabled={!isNameValid || !!error}
                    className={
                      !isNameValid || !!error
                        ? 'button-disabled'
                        : 'button-enabled'
                    }
                  >
                    Continuar
                  </button>
                </div>
              </div>
            )}
            <div ref={formRef} className="content__form-avatares">
              {step === 2 && (
                <>
                  <StyledTitle
                    className="title__main"
                    fontColor={
                      fetchedData?.content[0]?.folds[0]?.startForm?.itemForm[0]
                        ?.itemsStartForm?.titleColor
                    }
                    fontSize={
                      width <= 991
                        ? '56px'
                        : fetchedData?.content[0]?.folds[0]?.startForm
                            ?.itemForm[0]?.itemsStartForm?.titleSize
                    }
                  >
                    {`${name}${fetchedData?.content[0]?.folds[0]?.startForm?.itemForm[1]?.itemsStartForm?.titleForm}`}
                  </StyledTitle>
                  <div className="item-avatars">
                    <Swiper
                      navigation
                      slidesPerView={1}
                      spaceBetween={10}
                      modules={[Pagination, Navigation]}
                      pagination={{ clickable: true }}
                      autoHeight
                      className="swiperDynamic"
                      breakpoints={{
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                      }}
                      slidesPerGroup={1}
                      loop={true}
                      onSlideNextTransitionStart={(swiper: SwiperClass) =>
                        handleNextClick(swiper)
                      }
                      onSlidePrevTransitionStart={(swiper: SwiperClass) =>
                        handlePrevClick(swiper)
                      }
                    >
                      {avatars.map((item) => (
                        <SwiperSlide key={item.avatar.id}>
                          <div className="item-avatars-item">
                            <div
                              className={`avatar-container ${
                                selectedOption === `${item.avatar.id}`
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={(e) =>
                                handleAvatarSelection(`${item.avatar.id}`, e)
                              }
                            >
                              <img
                                className="item-avatars-img"
                                src={
                                  selectedOption === `${item.avatar.id}`
                                    ? getActiveAvatarImage(
                                        item.avatar.avatarName,
                                      )
                                    : `${base}${item.avatar.image.url}`
                                }
                                alt={item.avatar.avatarName || 'Avatar'}
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  {error && <p className="error-form">{error}</p>}
                  <div className="content__button">
                    <button
                      onClick={() => {
                        if (selectedOption) {
                          const submitEndPoint =
                            fetchedData?.content[0]?.folds[0]?.startForm
                              ?.itemForm[1]?.itemsStartForm?.submitEndPoint ||
                            '';
                          const component =
                            fetchedData?.content[0]?.folds[0]?.startForm
                              ?.itemForm[1]?.itemsStartForm?.foldsForm[0]
                              ?.__component || '';
                          const componentTwo =
                            fetchedData?.content[0]?.folds[0]?.startForm
                              ?.itemForm[0]?.itemsStartForm?.foldsForm[0]
                              ?.__component || '';
                          const endPointFull =
                            fetchedData?.content[0]?.folds[0]?.startForm
                              ?.submitEndPoint || '';

                          handleSubmitForm(
                            submitEndPoint,
                            selectedOption,
                            component,
                          );
                          handleSubmit(
                            endPointFull,
                            selectedOption,
                            component,
                            name,
                            componentTwo,
                          );
                        }
                      }}
                      disabled={!selectedOption}
                      className={
                        !selectedOption ? 'button-disabled' : 'button-enabled'
                      }
                    >
                      Continuar
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
