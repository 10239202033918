export const getGtmArgs = (
  category: string,
  action: string,
  label: string = '',
  interaction: string,
  name: string,
  text: string,
  userId?: string,
) => {
  return {
    gtmId: process.env.REACT_APP_API_GTM_ID,
    dataLayer: {
      event: 'GAEvent',
      event_category: category,
      event_action: action,
      event_label: label,
      interaction,
      component_name: name,
      element_text: text,
      userId,
    },
  };
};

export const getGtmArgsSecurity = (
  event_name?: string,
  name?: string,
  component_type?: string,
  text?: string,
  campaign_name?: string,
) => {
  const dataLayer: {
    event: string;
    event_name?: string;
    component_name?: string;
    component_type?: string;
    element_text?: string;
    campaign_name?: string;
  } = {
    event: 'GAEvent',
  };

  if (event_name) dataLayer.event_name = event_name;
  if (name) dataLayer.component_name = name;
  if (component_type) dataLayer.component_type = component_type;
  if (text && text.trim() !== '') dataLayer.element_text = text;
  if (campaign_name) dataLayer.campaign_name = campaign_name;

  return {
    gtmId: process.env.REACT_APP_API_GTM_ID,
    dataLayer,
  };
};
