import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import { getAPIToken, deleteSession } from '../../api/userSession';
import { getDomain } from '../../config/domain';
import { appURL, ssoURL } from '../../config/environment';
import { client_id } from '../../modules/services/sections';
import LoadSpinner from '../load-spinner/load-spinner';

const Callback = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  useEffect(() => {
    fetchSSO();
  }, []);
  const persistFetchSSO = async () => {
    let codeVerifier = localStorage.getItem('code_verifier');
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    localStorage.setItem(
      'redirect2',
      `${getDomain(window.location.hostname + '').appURL}/auth/callback`,
    );
    var raw = JSON.stringify({
      client_id: client_id,
      code_verifier: codeVerifier,
      grant_type: 'authorization_code',
      redirect_uri: `${getDomain(window.location.hostname + '').appURL
        }/auth/callback`,
      code: params?.code,
    });

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    await fetch(`${ssoURL}/oauth/token`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let accessToken = JSON.parse(result).access_token.toString();
        let expiresIn = JSON.parse(result).expires_in.toString();
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('expiresIn', expiresIn);
        var decoded: any = jwt_decode(result);
        let sharp = decoded.sharp_id || '';
        localStorage.setItem('sharp', sharp);
        let t = getAPIToken(
          localStorage.getItem('accessToken')?.toString() || '',
          localStorage.getItem('expiresIn')?.toString() || '',
        );
      })
      .catch(async (error) => {
        await fetch(`${ssoURL}/oauth/token`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            let accessToken = JSON.parse(result).access_token.toString();
            let expiresIn = JSON.parse(result).expires_in.toString();
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('expiresIn', expiresIn);
            var decoded: any = jwt_decode(result);
            let sharp = decoded.sharp_id || '';
            localStorage.setItem('sharp', sharp);
            getAPIToken(
              localStorage.getItem('accessToken')?.toString() || '',
              localStorage.getItem('expiresIn')?.toString() || '',
            );
          })
          .catch((error) => {
            console.log('error', error);
            localStorage.setItem('errorSession', error);
            deleteSession();
          });
      });


  }
  const fetchSSO = async () => {

    if (params.state === localStorage.getItem('state')) {
      let codeVerifier = localStorage.getItem('code_verifier');
      if (codeVerifier) {
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        localStorage.setItem(
          'redirect2',
          `${getDomain(window.location.hostname + '').appURL}/auth/callback`,
        );
        var raw = JSON.stringify({
          client_id: client_id,
          code_verifier: codeVerifier,
          grant_type: 'authorization_code',
          redirect_uri: `${getDomain(window.location.hostname + '').appURL
            }/auth/callback`,
          code: params?.code,
        });

        var requestOptions: RequestInit = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        await fetch(`${ssoURL}/oauth/token`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            let accessToken = JSON.parse(result).access_token.toString();
            let expiresIn = JSON.parse(result).expires_in.toString();
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('expiresIn', expiresIn);
            var decoded: any = jwt_decode(result);
            let sharp = decoded.sharp_id || '';
            localStorage.setItem('sharp', sharp);
            let t = getAPIToken(
              localStorage.getItem('accessToken')?.toString() || '',
              localStorage.getItem('expiresIn')?.toString() || '',
            );
          })
          .catch((error) => {
          });
      }
    }
  }
  return (
    <div>
      <LoadSpinner />
    </div>
  );
};

export default Callback;
