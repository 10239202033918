import styled from 'styled-components';

interface StyledProps {
  bgColor?: string;
  backgroundImage?: string;
}

interface StyledTitleProps {
  fontSize?: string;
  fontColor?: string;
}

export const StyledSeccion = styled.div<StyledProps>`
  background-color: ${(props) => props?.bgColor ?? "#fff"};
`;

export const StyledSectionContent = styled.div<StyledProps>`
  background-image: url(${(props) => props?.backgroundImage});
`;

export const StyledTitle = styled.h2<StyledTitleProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "64px")};
  color: ${(props) => (props.fontColor ? props.fontColor : "#fff")};
`;
