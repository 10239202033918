import axios from 'axios';

const CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export interface Avatar {
  image: { url: string };
  id: number;
  avatarName: string;
}

export interface FieldAvatar {
  avatar: Avatar;
}

export interface ItemsStartForm {
  backgroundImageDesk: { url: string };
  titleColor: string;
  titleSize: string;
  titleForm: string;
  submitEndPoint: string;
  foldsForm: { __component: string; fieldAvatar?: FieldAvatar[] }[];
}

export interface ItemForm {
  itemsStartForm: ItemsStartForm;
}

export interface StartForm {
  itemForm: ItemForm[];
  submitEndPoint?: string;
}

export interface Fold {
  startForm: StartForm;
}

export interface Content {
  folds: Fold[];
}

export interface FetchedData {
  content: Content[];
}

export async function getTokenByAPI(token: string) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const referred = localStorage.getItem('referred');

  const fullEndpointone = referred
    ? `${baseUrl}/securitymatch/star-game/code?referred=${referred}`
    : `${baseUrl}/securitymatch/star-game/code`;

  const headers = {
    'Content-Type': 'application/json',
    token: `${token}`,
  };

  return await axios.get(fullEndpointone, { headers });
}

export async function Zonadeusurio(
  endpoint: string,
  tokenJwt: string,
): Promise<FetchedData | null> {
  try {
    // Primero, intentamos obtener los datos del localStorage
    const cachedData = localStorage.getItem('zonadeusurio_data');
    if (cachedData) {
      return JSON.parse(cachedData);
    }

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const endpointStart = `${baseUrl}/${endpoint}`;

    const response = await fetch(endpointStart, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tokenJwt}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: FetchedData = await response.json();

    // Guardamos los datos en localStorage
    localStorage.setItem('zonadeusurio_data', JSON.stringify(data));

    return data;
  } catch (error) {
    console.error('Error in Zonadeusurio:', error);
    return null;
  }
}

// Función para limpiar los datos en caché
export function clearZonadeusurioCachedData(): void {
  if (localStorage.getItem('myEndpoint') !== '/securitymatch/start-form') {
    localStorage.removeItem('zonadeusurio_data');
  }
}

// Función para obtener los datos en caché sin hacer una nueva solicitud
export function getCachedZonadeusurio(): FetchedData | null {
  const cachedData = localStorage.getItem('zonadeusurio_data');
  return cachedData ? JSON.parse(cachedData) : null;
}

export async function FormSubmit(
  endpoint: string,
  tokenJwt: string,
  inputValue: string,
  component: string,
) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const endpointSubmitForm = `${baseUrl}/securitymatch${endpoint}`;
  const headers = {
    Authorization: `Bearer ${tokenJwt}`,
    'Content-Type': 'application/json',
  };
  const body = JSON.stringify([
    {
      id: 1,
      __component: component,
      value: inputValue,
    },
  ]);
  return await axios.put(endpointSubmitForm, body, { headers });
}

export async function FormSubmitFull(
  endpoint: string,
  tokenJwt: string,
  inputValue: string,
  component: string,
  inputValueTwo: string,
  componentTwo: string,
) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const tokenfull = localStorage.getItem('accessToken');

  const endpoitSubmitFormFull = `${baseUrl}/securitymatch${endpoint}`;

  const headers = {
    Authorization: `Bearer ${tokenJwt}`,
    'Content-Type': 'application/json',
    token: `${tokenfull}`,
  };

  const body = JSON.stringify([
    {
      id: 1,
      __component: component,
      value: inputValue,
    },
    {
      id: 1,
      __component: componentTwo,
      value: inputValueTwo,
    },
  ]);
  return await axios.put(endpoitSubmitFormFull, body, { headers });
}

export async function getRanking(tokenJwt: string) {
  try {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullEndpoint = `${baseUrl}/securitymatch/ranking`;

    const response = await fetch(fullEndpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tokenJwt}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error Ranking:', error);
  }
}

export async function getUserZone(tokenJwt: string): Promise<any> {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const endpointGetUserZone = `${baseUrl}/securitymatch/user-zone`;
  const headers = {
    Authorization: `Bearer ${tokenJwt}`,
    'Content-Type': 'application/json',
  };
  return await axios.get(endpointGetUserZone, { headers });
}

export const getQuestion = async (tokenJwt: string, questionId: string) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  try {
    const response = await axios.get(
      `${baseUrl}/securitymatch/pregunta/${questionId}`,
      {
        headers: {
          Authorization: `Bearer ${tokenJwt}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response;
  } catch (error) {
    console.error('Error en getQuestion:', error);
    throw error; // Re-lanzamos el error para manejarlo en el componente
  }
};

export async function getOnboarding(tokenJwt: string) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  //const fullEndpointthree = `${baseUrl}/securitymatch${endpoint}`;
  const endpointOnboarding = `${baseUrl}/securitymatch/onboarding`;
  const headers = {
    Authorization: `Bearer ${tokenJwt}`,
    'Content-Type': 'application/json',
  };
  return await axios.get(endpointOnboarding, { headers });
}

export async function getQuestionSubmit(
  tokenJwt: string,
  questionId: string,
  answer: boolean,
  time: number,
  isDemo: boolean,
) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  //const fullEndpointthree = `${baseUrl}/securitymatch${endpoint}`;
  const endpointQuestionSubmit = `${baseUrl}/securitymatch/question/${questionId}`;
  const headers = {
    Authorization: `Bearer ${tokenJwt}`,
    'Content-Type': 'application/json',
  };
  const body = JSON.stringify([
    {
      answer: answer,
      time: time,
      isDemo: isDemo,
    },
  ]);
  return await axios.put(endpointQuestionSubmit, body, { headers });
}

export const getResult = async (token: string, myEndpoint: string) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  try {
    // Asegurarse de que no haya doble 'securitymatch' en la URL
    const endpoint = myEndpoint.startsWith('securitymatch')
      ? myEndpoint
      : `securitymatch/${myEndpoint}`;

    const response = await axios.get(`${baseUrl}/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    console.error('Error en getResult:', error);
    throw error;
  }
};

export async function FormEmailSubmit(
  endpoint: string,
  tokenJwt: string,
  inputValue: string,
  component: string,
) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const fullEndpointtwo = `${baseUrl}/securitymatch${endpoint}`;
  const headers = {
    Authorization: `Bearer ${tokenJwt}`,
    'Content-Type': 'application/json',
  };
  const body = JSON.stringify([
    {
      id: 1,
      __component: component,
      value: inputValue,
    },
  ]);
  return await axios.put(fullEndpointtwo, body, { headers });
}
