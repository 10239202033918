import { useEffect, useState } from 'react';

export const useResponsive = () => {
  // todo este helper permite saber la medida de la pantalla que esta actualmente
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const callback = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', callback);

    return () => {
      window.removeEventListener('resize', callback);
    };
  }, []);

  return { width };
};
