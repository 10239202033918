import React, { useState, useEffect, useRef } from 'react';
import { getResult } from '../../../api/security/index';

import {
  StyledSeccion,
  StyledTitle,
  StyledSectionContent,
  StyledContainer,
  StyledContainerParagraph,
  StyledParagraph,
  StyledImg,
  StyledButton,
  StyledTitleHelp,
} from './Acordeon.styles';

import { useLocation } from 'react-router-dom';
import {
  gtmSecurityFaqsMore,
  gtmSecurityFaqsLess,
} from '../../../tools/datalayers';

interface Icon {
  url: string;
}

interface Image {
  url: string;
}

interface Button {
  id: number;
  labelBtn: string;
  backgroundColorBtn: string;
  fontColorBtn: string;
  url: string;
  target: string;
}

interface Item {
  description: string;
  title: string;
  id: number;
  buttom?: Button[];
}

interface Fold {
  title: string;
  icontitle: Image;
  item: Item[];
  backgroundColorItem: string;
  fontColorItemTitle: string;
}

interface BackgroundImgPage {
  url: string;
}

interface BackgroundImgPageMobile {
  url: string;
}

interface MenuItem {
  iconOff: Icon;
  iconOn: Icon;
  page: {
    slug: string;
  };
}

interface Content {
  backgroundPage: string;
  backgroundImgPage: BackgroundImgPage;
  backgroundImgPageMobile: BackgroundImgPageMobile;
  folds: Fold[];
  menu: MenuItem[];
  endpoint: string;
}

interface ResultData {
  content: Content[];
}

export const Faqs = () => {
  const [resultData, setFetchedData] = useState<ResultData | null>(null);
  const [menu, setMenu] = useState<MenuItem[] | null>(null);
  const [isActive, setIsActive] = useState<{ [key: number]: boolean }>({});
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const location = useLocation();
  const base = process.env.REACT_APP_APP_URL;
  const baseApi = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('myAccessToken') || 'myAccessToken';

      // Extraer el endpoint de la ruta actual
      const pathSegments = location.pathname.split('/').filter(Boolean);
      const currentEndpoint =
        pathSegments.length >= 2
          ? `${pathSegments[0]}/${pathSegments[1]}`
          : 'securitymatch/preguntas-frecuentes'; // valor por defecto

      try {
        const { data } = await getResult(token, currentEndpoint);

        if (data?.content?.[0]) {
          setFetchedData(data);
          setMenu(data.content[0]?.menu || []);

          // Actualizar myEndpoint en localStorage solo si es diferente
          if (
            data.content[0]?.endpoint &&
            data.content[0].endpoint !== currentEndpoint
          ) {
            localStorage.setItem('myEndpoint', data.content[0].endpoint);
          } else {
            localStorage.setItem('myEndpoint', currentEndpoint);
          }
        } else {
          console.error('No se encontraron datos válidos');
        }
      } catch (error) {
        console.error('Error de servicio', error);
      }
    };

    fetchData();
  }, [location.pathname]);

  const handleToggle = (index: number) => {
    const activeTitle =
      resultData?.content[0].folds[0]?.item[index]?.title || '';

    if (index === activeIndex) {
      // Contrayendo la pregunta
      setActiveIndex(null);
      localStorage.removeItem('activeQuestionDescription');
      gtmSecurityFaqsLess(activeTitle);
    } else {
      // Expandiendo la pregunta
      setActiveIndex(index);
      if (activeTitle) {
        localStorage.setItem('activeQuestionDescription', activeTitle);
      }
      gtmSecurityFaqsMore(activeTitle);
    }
  };

  const close = `${process.env.PUBLIC_URL}/security/icon-minus.svg`;
  const mas = `${process.env.PUBLIC_URL}/security/icon-mas-acordeon.svg`;

  const currentPath = window.location.pathname.replace(/^\/+/, '');

  return (
    <>
      <StyledSeccion
        className="help"
        bgColor={resultData?.content[0]?.backgroundPage}
      >
        <picture className="content__bg">
          <source
            srcSet={`${baseApi}${resultData?.content[0]?.backgroundImgPage?.url}`}
            media="(min-width: 780px)"
          />
          <img
            className="ranking__img"
            src={`${baseApi}${resultData?.content[0]?.backgroundImgPageMobile?.url}`}
          />
        </picture>
        <div className="help__container">
          <div className="help__content-text">
            <img
              className="help__img"
              src={`${baseApi}${resultData?.content[0]?.folds[0]?.icontitle?.url}`}
              alt=""
            />
            <StyledTitleHelp
              className="help__title"
              color={resultData?.content[0]?.folds[0]?.fontColorItemTitle}
            >
              {resultData?.content[0]?.folds[0]?.title}
            </StyledTitleHelp>
          </div>
          <StyledSectionContent
            data-testid="banneraccordion"
            className="banneraccordion"
          >
            <div className="banneraccordion__container">
              <div className="banneraccordion__content">
                {resultData?.content[0].folds[0]?.item?.map(
                  ({ description, title, buttom }, index) => (
                    <StyledSeccion
                      data-testid="main-container"
                      bgColor={
                        resultData?.content[0]?.folds[0]?.backgroundColorItem
                      }
                      className={`banneraccordion__item ${
                        activeIndex === index ? 'active' : ''
                      }`}
                    >
                      <StyledContainer
                        data-testid="container"
                        //isActive={isActive[index]}
                      >
                        <StyledTitle
                          //isActive={isActive[index]}
                          className="accordion__title"
                        >
                          {title}
                        </StyledTitle>
                        <StyledButton
                          data-testid="button"
                          onClick={() => handleToggle(index)}
                        >
                          <StyledImg
                            src={activeIndex === index ? close : mas} // Cambia la imagen dependiendo de si el ítem está activo
                            alt={activeIndex === index ? 'Cerrar' : 'Abrir'}
                          />
                        </StyledButton>
                      </StyledContainer>
                      <StyledContainerParagraph
                        data-testid="paragraph-container"
                        className="accordion__contentext"
                        isActive={activeIndex === index}
                      >
                        <StyledParagraph
                          className="accordion__description"
                          dangerouslySetInnerHTML={{ __html: description }}
                        ></StyledParagraph>
                        {buttom &&
                          buttom?.map(
                            ({
                              id,
                              labelBtn,
                              backgroundColorBtn,
                              fontColorBtn,
                              url,
                              target,
                            }) => (
                              <StyledButton
                                key={id}
                                as="a" // Para que sea un enlace
                                href={url}
                                target={target}
                                style={{
                                  backgroundColor: backgroundColorBtn,
                                  color: fontColorBtn,
                                }}
                              >
                                {labelBtn}
                              </StyledButton>
                            ),
                          )}
                      </StyledContainerParagraph>
                    </StyledSeccion>
                  ),
                )}
              </div>
            </div>
          </StyledSectionContent>
        </div>
        {menu && (
          <div className="menu">
            <div className="menu__container">
              <div className="menu__content">
                {Array.isArray(menu) &&
                  menu?.map((item, index) => (
                    <div key={index}>
                      <img
                        src={`${baseApi}${
                          currentPath === item?.page?.slug
                            ? item?.iconOn?.url
                            : item?.iconOff?.url
                        }`}
                        alt={item?.page?.slug}
                        className={`item-avatars-img ${
                          currentPath === item?.page?.slug ? 'active' : ''
                        }`}
                        onClick={() => {
                          if (item?.page?.slug) {
                            const fullUrl = `${window.location.origin}/${item?.page?.slug}`;
                            window.open(fullUrl, '_self');
                          }
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </StyledSeccion>
    </>
  );
};
