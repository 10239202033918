import styled from "styled-components";

interface StyledTitleProps {
  size?: string;
  color?: string;
  sizeDesktop?: string;
}

interface StyledProps {
  bgColor?: string;
}


export const StyledTitle = styled.h2<StyledTitleProps>`
  color: ${(props) => (props?.color ? props?.color : "#fff")};
  transition: all 0.4s ease;
`;

export const StyledSeccion = styled.div<StyledProps>`
  background-color: ${(props) => props?.bgColor ?? "#fff"};
`;