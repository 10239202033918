import React, { useState, useEffect, useRef } from 'react';
import { getResult } from '../../../api/security/index';
import { useResponsive } from '../../../helpers/responsive/useResponsive';

import { StyledSeccion, StyledTitle } from './result.styles';
import { useLocation } from 'react-router-dom';

interface Image {
  url: string;
}

interface Data {
  points: number;
  note: string;
}

interface Fold {
  title: string;
  image: Image;
  data: Data;
  labelBtn: string;
  topics: {
    topic: string;
    image: Image;
    level: string;
    description: string;
  };
}

interface BackgroundImgPage {
  url: string;
}

interface BackgroundImgPageMobile {
  url: string;
}

interface Content {
  backgroundPage: string;
  backgroundImgPage: BackgroundImgPage;
  backgroundImgPageMobile: BackgroundImgPageMobile;
  folds: Fold[];
  endpoint: string;
}

interface ResultData {
  content: Content[];
}

export const Level = () => {
  const [resultData, setFetchedData] = useState<ResultData | null>(null);
  const { width } = useResponsive();

  const baseApi = process.env.REACT_APP_API_BASE_URL;

  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('myAccessToken') || 'myAccessToken';

      // Siempre usar la ruta actual para determinar el endpoint
      const pathSegments = location.pathname.split('/').filter(Boolean);
      const currentEndpoint =
        pathSegments.length >= 2
          ? `${pathSegments[0]}/${pathSegments[1]}`
          : 'securitymatch/nivel';

      // Limpiar el endpoint guardado en localStorage
      localStorage.removeItem('myEndpoint');

      try {
        const { data } = await getResult(token, currentEndpoint);

        setFetchedData(data);

        // Guardar el nuevo endpoint en localStorage
        if (data?.content?.[0]?.endpoint) {
          localStorage.setItem('myEndpoint', data.content[0].endpoint);
        } else {
          localStorage.setItem('myEndpoint', currentEndpoint);
        }
      } catch (error) {
        console.error('Error de servicio', error);
        // No guardamos nada en localStorage si hay un error
      }
    };

    fetchData();
  }, [location.pathname]);

  return (
    <>
      <StyledSeccion
        className="page correct"
        bgColor={resultData?.content[0]?.backgroundPage ?? 'fff'}
      >
        <picture className="content__bg">
          <source
            srcSet={`${baseApi}${resultData?.content[0]?.backgroundImgPage}`}
            media="(min-width: 780px)"
          />
          <img
            className="ranking__img"
            src={`${baseApi}${resultData?.content[0]?.backgroundImgPageMobile}`}
          />
        </picture>
        <div className="page__container">
          <div className="content__info">
            <StyledTitle
              className="title__result-two"
              fontSize={width <= 991 ? '10vw' : '96px'}
            >
              {resultData?.content[0]?.folds[0]?.topics?.topic}
            </StyledTitle>
            <div className="content-point">
              <img
                className="img-result"
                src={`${baseApi}${resultData?.content[0]?.folds[0]?.topics?.image}`}
              />
            </div>
            <div className="content__text-point">
              <p className="text-level">
                Nivel {resultData?.content[0]?.folds[0]?.topics?.level}
              </p>
            </div>
            <p
              className="alert__message"
              dangerouslySetInnerHTML={{
                __html:
                  resultData?.content[0]?.folds[0]?.topics?.description ?? '',
              }}
            ></p>
            <button
              onClick={() => {
                if (resultData?.content[0]?.endpoint) {
                  const fullUrl = `${window.location.origin}/${resultData?.content[0]?.endpoint}`;
                  //const fullUrlPregunta = `${window.location.origin}/securitymatch/pregunta`;
                  localStorage.setItem(
                    'myEndpoint',
                    resultData?.content[0]?.endpoint,
                  );
                  window.open(fullUrl, '_self');
                  //window.open(fullUrlPregunta, '_self');
                }
              }}
            >
              {resultData?.content[0]?.folds[0]?.labelBtn}
            </button>
          </div>
        </div>
      </StyledSeccion>
    </>
  );
};
