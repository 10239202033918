import styled from "styled-components";

interface StyledTitleProps {
  fontSize?: string;
  fontColor?: string;
}

export const StyledTitle = styled.h2<StyledTitleProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "64px")};
  color: ${(props) => (props.fontColor ? props.fontColor : "#fff")};
`;

export const StyledSubTitle = styled.p<StyledTitleProps>`
  color: ${(props) => (props.fontColor ? props.fontColor : "#fff")};
`;