import { getGtmArgs, getGtmArgsSecurity } from './GtmArgs';
import TagManager from 'react-gtm-module';

export const gtmLogIn = () => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Home',
      'true',
      'button_enternow',
      'Ingresar ahora',
    ),
  );
};

export const gtmFooter = (name: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Other Section',
      'true',
      'button_footer',
      name,
    ),
  );
};

export const gtmHeader = (name: string) => {
  TagManager.dataLayer(
    getGtmArgs('Content', 'button', 'Header', 'true', 'button_header', name),
  );
};

export const gtmProfileMenu = (name: string) => {
  TagManager.dataLayer(
    getGtmArgs('Content', 'button', 'User Menu', 'true', 'button_menu', name),
  );
};

export const gtmLogOut = () => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'User Menu',
      'true',
      'button_menu',
      'Log Out',
    ),
  );
};

export const gtmFeatured = (articleName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Destacado',
      'true',
      'button_knowmore',
      articleName,
    ),
  );
};

export const gtmNewsletter = (newsName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'Card',
      'Newsletter',
      'true',
      'button_knowmore',
      newsName,
    ),
  );
};

export const gtmFuture = (articleName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Somos Futuro',
      'true',
      'button_knowmore',
      articleName,
    ),
  );
};

export const gtmSendEmail = () => {
  TagManager.dataLayer(
    getGtmArgs('Newsletter', 'Click', 'Submit', 'true', 'button_send', ''),
  );
};

export const gtmEmailSaved = (userId: string) => {
  TagManager.dataLayer(
    getGtmArgs('Newsletter', 'Success', '', 'true', 'button_send', '', userId),
  );
};

export const gtmOffices = (officeName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Nuestras Oficinas',
      'true',
      'button_knowmore',
      officeName,
    ),
  );
};

export const gtmAboutUs = (sectionName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Sobre Nosotros',
      'true',
      'button_knowmore',
      sectionName,
    ),
  );
};

export const gtmCommunities = (sectionName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'C & G',
      'true',
      'button_knowmore',
      sectionName,
    ),
  );
};

export const gtmOurPeople = (sectionName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Nuestra Gente',
      'true',
      'button_knowmore',
      sectionName,
    ),
  );
};

export const gtmSharepoint = (articleName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Articulo',
      'true',
      'button_linksp',
      articleName,
    ),
  );
};

export const gtmRelatedArticle = (articleName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Articulo',
      'true',
      'button_readarticle',
      articleName,
    ),
  );
};

export const gtmVideo = (videoName: string) => {
  TagManager.dataLayer(getGtmArgs('Video', 'Play', videoName, 'true', '', ''));
};

export const gtmFullVideoPlayed = (videoName: string) => {
  TagManager.dataLayer(
    getGtmArgs('Video', 'Completed', videoName, 'true', '', ''),
  );
};

export const gtmCloseVideo = (videoName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Video',
      'true',
      'button_closevideo ',
      videoName,
      '',
    ),
  );
};

export const gtmSocialMedia = (socialMedia: string, articleName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Compartir RRSS',
      'true',
      'button_share',
      `${socialMedia}-${articleName}`,
    ),
  );
};

export const gtmAboutUsModal = () => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Sobre Nosotros',
      'true',
      'button_close',
      'Cerrar modal informativo',
    ),
  );
};

export const gtmCareers = (sectionName: string) => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Hack Your Brain',
      'true',
      'button_knowmore',
      sectionName,
    ),
  );
};

export const gtmChageProfilePhoto = () => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'ZDU',
      'true',
      'button_changedata',
      'Cambiar información de usuario',
    ),
  );
};

export const gtmSaveProfileinfo = () => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'ZDU',
      'true',
      'button_save',
      'Guardar cambios',
    ),
  );
};

export const gtmCampainVideosViewPage = () => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Embajador',
      'true',
      'button_uploadvideo',
      'Subir Video',
    ),
  );
};

export const gtmCampainVideosEnableSend = () => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Embajador',
      'true',
      'button_sendvideo',
      'Enviar Video',
    ),
  );
};

export const gtmCampainVideosSend = () => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'Content',
      'Embajador',
      'true',
      'modal_success',
      'Video Enviado',
    ),
  );
};

export const gtmCampainVideosCloseModal = () => {
  TagManager.dataLayer(
    getGtmArgs(
      'Content',
      'button',
      'Embajador',
      'true',
      'button_close',
      'Cerrar modal',
    ),
  );
};

export const gtmSecurityBotonEndlive = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction_game_over',
      'answer_board',
      'button',
      'entendido',
      'security_match',
    ),
  );
};

export const gtmSecurityCorrectAnwersButton = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction_correct_answer',
      'answer_board',
      'button',
      'continuar',
      'security_match',
    ),
  );
};

export const gtmSecurityCorrectAnwers = (questionName: string) => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction_correct_answer',
      'answer_board',
      'response',
      `correct_${questionName}`,
      'security_match',
    ),
  );
};

export const gtmSecurityIncorrectAnwersButton = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction_wrong_answer',
      'answer_board',
      'button',
      'continuar',
      'security_match',
    ),
  );
};

export const gtmSecurityIncorrectAnwers = (questionName: string) => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction_wrong_answer',
      'answer_board',
      'response',
      `wrong_${questionName}`,
      'security_match',
    ),
  );
};

export const gtmSecurityLoginSuccess = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction',
      'user_nickname_success',
      'button',
      'continuar',
      'security_match',
    ),
  );
};

export const gtmSecurityLoginError = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction',
      'user_nickname_error',
      'button',
      'continuar',
      'security_match',
    ),
  );
};

export const gtmSecurityArrowleft = (slideName: string) => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'user_avatar_selector',
      'campaign_slider',
      'button',
      `prev_${slideName}`,
      'security_match',
    ),
  );
};

export const gtmSecurityArrowRight = (slideName: string) => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'user_avatar_selector',
      'campaign_slider',
      'button',
      `next_${slideName}`,
      'security_match',
    ),
  );
};

export const gtmSecurityQuestionTrue = (content: string) => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction',
      'question_board',
      'button',
      `yes_${content}`,
      'security_match',
    ),
  );
};

export const gtmSecurityQuestionFalse = (content: string) => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction',
      'question_board',
      'button',
      `no_${content}`,
      'security_match',
    ),
  );
};

export const gtmSecurityBotonLevel = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction',
      'level_completed',
      'button',
      'continuar',
      'security_match',
    ),
  );
};

export const gtmSecurityUpLevel = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction',
      'answer_board ',
      'response',
      'nivel_completado',
      'security_match',
    ),
  );
};

export const gtmSecurityFaqsMore = (faqName: string) => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction_FAQs',
      'expand_question',
      'button',
      `more_${faqName}`,
      'security_match',
    ),
  );
};

export const gtmSecurityFaqsLess = (faqName: string) => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction_FAQs',
      'contract_question',
      'button',
      `less_${faqName}`,
      'security_match',
    ),
  );
};

export const gtmSecurityShareButtonCopy = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction',
      'engagement',
      'button',
      'copiar_url',
      'security_match',
    ),
  );
};

export const gtmSecurityShareSend = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'interaction',
      'engagement',
      'button',
      'enviar_invitacion',
      'security_match',
    ),
  );
};

export const gtmSecurityShareSendError = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'invitation_error',
      'share_Invitation',
      'button',
      'enviar_invitacion',
      'security_match',
    ),
  );
};

export const gtmSecurityShareSendSuccess = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'invitation_success',
      'share_Invitation',
      'response',
      '',
      'security_match',
    ),
  );
};

export const gtmSecurityShareSendSuccessButton = () => {
  TagManager.dataLayer(
    getGtmArgsSecurity(
      'invitation_success',
      'share_Invitation',
      'button',
      'entendido',
      'security_match',
    ),
  );
};
