import styled from 'styled-components';

interface StyledProps {
  fontColor?: string;
}

export const StyledTitle = styled.h3<StyledProps>`
  color: ${(props) => (props.fontColor ? props.fontColor : "#fff")};
`;

export const StyledSubTitle = styled.p<StyledProps>`
  color: ${(props) => (props.fontColor ? props.fontColor : "#fff")};
`;



