import React, { useState, useEffect, useRef } from 'react';
import { getRanking } from '../../../api/security/index';
import { useResponsive } from '../../../helpers/responsive/useResponsive';

import {
  StyledTitle,
  StyledSectionContent
} from './ranking.styles';
import { useParams } from 'react-router-dom';

// Define una interfaz para la estructura de rankingData
interface Icon {
  url: string;
}

interface TopRankingItem {
  nickName: string;
  email: string;
  points: number;
  avatar: Icon;
}

interface TopRanking {
  ranking: TopRankingItem[];
  rankingUser?: TopRankingItem & { position: number };
}

interface Fold {
  backgroundColor: string;
  iconTitle: Icon;
  colorFontTitle: string;
  title: string;
  topRanking: TopRanking;
}

interface BackgroundImgPage {
  url: string;
}

interface BackgroundImgPageMobile {
  url: string;
  alternativeText: string;
  caption: string;
}

interface MenuItem {
  iconOff: Icon;
  iconOn: Icon;
  page: {
    slug: string;
  };
}

interface Content {
  folds: Fold[];
  menu: MenuItem[];
  backgroundImgPage: BackgroundImgPage;
  backgroundImgPageMobile: BackgroundImgPageMobile;
}

interface RankingData {
  content: Content[];
}

export const Ranking = () => {
  const [rankingData, setFetchedData] = useState<RankingData | null>(null);
  const [menu, setMenu] = useState<MenuItem[] | null>(null);
  const { slug } = useParams<{ slug: string }>();
  const base = process.env.REACT_APP_API_BASE_URL;
  const { width } = useResponsive();

  useEffect(() => {
    const token = localStorage.getItem('myAccessToken') || '';

    getRanking(token)
      .then((data) => {
        setFetchedData(data);
        setMenu(data?.content[0]?.menu);
      })
      .catch((error) => {
        console.error('Error de servicio', error);
      });
  }, []);

  const currentPath = window.location.pathname.replace(/^\/+/, '');

  const renderRankingTable = (items: TopRankingItem[], startIndex: number) => (
    <table>
      <thead>
        <tr>
          <th>Puesto</th>
          <th>Usuario</th>
          <th>Puntaje</th>
          <th>Avatar</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr className="ranking__row" key={startIndex + index}>
            <td>
              <div className="ranking__item-position">{startIndex + index}</div>
            </td>
            <td>
              <div>
                <h2 className="ranking__item-title">{item.nickName}</h2>
                <p className="ranking__item-subtitle">{item.email}</p>
              </div>
            </td>
            <td>
              <div className="ranking__item-points">{item.points}</div>
            </td>
            <td>
              <img
                className="ranking__item-avatars"
                src={`${base}${item.avatar.url}`}
                alt=""
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderUserRanking = (user: TopRanking['rankingUser']) => {
    if (!user) return null;

    return (
      <div
        className='ranking__content-user'
      >
        <table>
          <thead>
            <tr>
              <th>Puesto</th>
              <th>Usuario</th>
              <th>Puntaje</th>
              <th>Avatar</th>
            </tr>
          </thead>
          <tbody>
            <tr className="ranking__row">
              <td>
                <div className="ranking__item-position">{user.position}</div>
              </td>
              <td>
                <div>
                  <h2 className="ranking__item-title">{user.nickName}</h2>
                  <p className="ranking__item-subtitle">{user.email}</p>
                </div>
              </td>
              <td>
                <div className="ranking__item-points">{user.points}</div>
              </td>
              <td>
                <img
                  className="ranking__item-avatars"
                  src={`${base}${user.avatar.url}`}
                  alt=""
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="ranking">
      <picture className="ranking__bg">
        <source
          srcSet={`${base}${rankingData?.content[0]?.backgroundImgPage?.url}`}
          media="(min-width: 780px)"
        />
        <img
          className="ranking__img"
          alt={
            rankingData?.content[0]?.backgroundImgPageMobile?.alternativeText
          }
          title={rankingData?.content[0]?.backgroundImgPageMobile?.caption}
          src={`${base}${rankingData?.content[0]?.backgroundImgPageMobile?.url}`}
        />
      </picture>
      <div className="ranking__container">
        <StyledSectionContent
          bgColor={rankingData?.content[0]?.folds[0]?.backgroundColor}
          className="ranking__content"
        >
          <div className="ranking__content-text">
            <img
              className="ranking__content-img"
              src={`${base}${rankingData?.content[0]?.folds[0]?.iconTitle?.url}`}
              alt=""
            />
            <StyledTitle
              className="ranking__title"
              fontColor={rankingData?.content[0]?.folds[0]?.colorFontTitle}
              fontSize={width <= 991 ? '40px' : '64px'}
            >
              {rankingData?.content[0]?.folds[0]?.title}
            </StyledTitle>
          </div>
          <div>
            {renderRankingTable(
              rankingData?.content[0]?.folds[0]?.topRanking?.ranking?.slice(
                0,
                3,
              ) || [],
              1,
            )}
          </div>
        </StyledSectionContent>
        <div className="ranking__content-bottom">
          {renderRankingTable(
            rankingData?.content[0]?.folds[0]?.topRanking?.ranking?.slice(3) ||
              [],
            4,
          )}
        </div>
        {renderUserRanking(
          rankingData?.content[0]?.folds[0]?.topRanking?.rankingUser,
        )}
      </div>
      {menu && (
        <div className="menu">
          <div className="menu__container">
            <div className="menu__content">
              {menu.map((item, index) => (
                <div key={index}>
                  <img
                    src={`${base}${
                      currentPath === item?.page?.slug
                        ? item?.iconOn?.url
                        : item?.iconOff?.url
                    }`}
                    alt={item?.page?.slug}
                    className={`item-avatars-img ${
                      currentPath === item?.page?.slug ? 'active' : ''
                    }`}
                    onClick={() => {
                      if (item?.page?.slug) {
                        const fullUrl = `${window.location.origin}/${item?.page?.slug}`;
                        window.open(fullUrl, '_self');
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
