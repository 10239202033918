import { eventNames } from 'process';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

import {
  gtmSecurityQuestionTrue,
  gtmSecurityQuestionFalse,
} from '../../../tools/datalayers';

interface SwipeableCardProps {
  content: string;
  onSwipe: (isRight: boolean) => void;
  backgroundImage: string;
  correctImageUrl: string;
  incorrectImageUrl: string;
}

const SwipeableCard: React.FC<SwipeableCardProps> = ({
  content,
  onSwipe,
  backgroundImage,
  correctImageUrl,
  incorrectImageUrl,
}) => {
  const [showFeedback, setShowFeedback] = useState<boolean | null>(null);
  const [swipeDirection, setSwipeDirection] = useState<'left' | 'right' | null>(
    null,
  );
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSwipe = useCallback(
    (isRight: boolean) => {
      if (isMounted.current) {
        setShowFeedback(true);
        setSwipeDirection(isRight ? 'right' : 'left');
      }

      // Esperar 500ms antes de llamar a onSwipe para que la animación se muestre
      setTimeout(() => {
        if (isMounted.current) {
          onSwipe(isRight);
        }
      }, 500);

      // Resetear el estado después de la animación
      setTimeout(() => {
        if (isMounted.current) {
          setSwipeDirection(null);
          setShowFeedback(null);
        }
      }, 1000);
    },
    [onSwipe],
  );

  useEffect(() => {
    const preventDefault = (e: TouchEvent) => {
      if (
        e.touches.length === 1 &&
        Math.abs(e.touches[0].clientY - touchStartY) >
          Math.abs(e.touches[0].clientX - touchStartX)
      ) {
        e.preventDefault();
      }
    };

    let touchStartX = 0;
    let touchStartY = 0;

    const setTouchStart = (e: TouchEvent) => {
      touchStartX = e.touches[0].clientX;
      touchStartY = e.touches[0].clientY;
    };

    document.body.style.overscrollBehavior = 'none';
    document.addEventListener('touchmove', preventDefault, { passive: false });
    document.addEventListener('touchstart', setTouchStart, { passive: false });

    return () => {
      document.body.style.overscrollBehavior = 'auto';
      document.removeEventListener('touchmove', preventDefault);
      document.removeEventListener('touchstart', setTouchStart);
    };
  }, []);

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      if (eventData.dir === 'Left' || eventData.dir === 'Right') {
        setSwipeDirection(eventData.dir === 'Left' ? 'left' : 'right');
      }
    },
    onSwipedLeft: () => {
      gtmSecurityQuestionFalse(content);
      handleSwipe(false);
    },
    onSwipedRight: () => {
      gtmSecurityQuestionTrue(content);
      handleSwipe(true);
    },
    trackMouse: false,
    trackTouch: true,
    delta: 50,

    rotationAngle: 0,
  });

  return (
    <div
      {...handlers}
      className="content__game-container"
      style={{
        touchAction: 'none',
        userSelect: 'none',
        overflow: 'hidden',
      }}
    >
      <div
        className="content__game-content"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <p>{content}</p>
      </div>
      <img
        src={incorrectImageUrl}
        alt="False"
        style={{
          position: 'absolute',
          left: '-80px',
          top: '50%',
          transform: `translateY(-50%) translateX(${
            swipeDirection === 'left' ? '50px' : '0'
          })`,
          transition: 'transform 0.3s ease-out',
          width: '150px',
          height: '150px',
        }}
      />
      <img
        src={correctImageUrl}
        alt="True"
        style={{
          position: 'absolute',
          right: '-80px',
          top: '50%',
          transform: `translateY(-50%) translateX(${
            swipeDirection === 'right' ? '-50px' : '0'
          })`,
          transition: 'transform 0.3s ease-out',
          width: '150px',
          height: '150px',
        }}
      />
      {showFeedback !== null && (
        <div
          className="feedback-overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            transition: 'opacity 0.3s ease-out',
            opacity: showFeedback ? 1 : 0,
          }}
        >
          <img
            src={
              swipeDirection === 'right' ? correctImageUrl : incorrectImageUrl
            }
            alt={swipeDirection === 'right' ? 'True' : 'False'}
            style={{ maxWidth: '50%', maxHeight: '50%' }}
          />
        </div>
      )}
    </div>
  );
};

export default SwipeableCard;
