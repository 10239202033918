import React, { useEffect, useRef, useState } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  path: string;
  [key: string]: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const location = useLocation();
  const history = useHistory();
  const navigationStack = useRef<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const componentRef = useRef<HTMLDivElement>(null);
  const pendingImagesRef = useRef<Set<HTMLImageElement>>(new Set());
  const loadingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const updateMyEndpoint = (path: string) => {
    let formattedPath = path.startsWith('/') ? path.slice(1) : path;
    if (!formattedPath.startsWith('securitymatch/')) {
      formattedPath = `securitymatch/${formattedPath}`;
    }
    localStorage.setItem('myEndpoint', formattedPath);
  };

  const checkAccessToken = () => {
    const myAccessToken = localStorage.getItem('myAccessToken');
    if (!myAccessToken) {
      history.replace('/');
      return false;
    }
    return true;
  };

  useEffect(() => {
    const handleNavigation = () => {
      if (!checkAccessToken()) return;

      updateMyEndpoint(location.pathname);
      navigationStack.current.push(location.pathname);

      if (navigationStack.current.length > 2) {
        navigationStack.current = navigationStack.current.slice(-2);
      }

      if (
        navigationStack.current.length === 2 &&
        navigationStack.current[0] !== '/' &&
        navigationStack.current[1] !== '/securitymatch'
      ) {
        history.replace('/securitymatch');
      }

      setIsLoading(true);
      pendingImagesRef.current.clear();
    };

    handleNavigation();

    const handleFocus = () => {
      if (checkAccessToken()) {
        navigationStack.current = [location.pathname];
        updateMyEndpoint(location.pathname);
      }
    };

    const handlePopState = () => {
      if (checkAccessToken()) {
        history.replace('/securitymatch');
      }
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [history, location]);

  useEffect(() => {
    if (!componentRef.current) return;

    const handleImageLoad = (img: HTMLImageElement) => {
      pendingImagesRef.current.delete(img);
      checkAllElementsLoaded();
    };

    const handleImageError = (img: HTMLImageElement) => {
      pendingImagesRef.current.delete(img);
      checkAllElementsLoaded();
    };

    const checkAllElementsLoaded = () => {
      if (pendingImagesRef.current.size === 0) {
        if (loadingTimeoutRef.current) {
          clearTimeout(loadingTimeoutRef.current);
        }
        loadingTimeoutRef.current = setTimeout(() => {
          setIsLoading(false);
        }, 1000); // Aumentado a 1 segundo para asegurar que todo esté renderizado
      }
    };

    const observerOptions = {
      childList: true,
      subtree: true,
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof HTMLImageElement) {
              handleNewImage(node);
            } else if (node instanceof Element) {
              const images = node.getElementsByTagName('img');
              Array.from(images).forEach(handleNewImage);
            }
          });
        }
      });
    });

    const handleNewImage = (img: HTMLImageElement) => {
      if (!img.complete) {
        pendingImagesRef.current.add(img);
        img.addEventListener('load', () => handleImageLoad(img));
        img.addEventListener('error', () => handleImageError(img));
      }
    };

    observer.observe(componentRef.current, observerOptions);

    // Manejar imágenes existentes
    const existingImages = componentRef.current.getElementsByTagName('img');
    Array.from(existingImages).forEach(handleNewImage);

    checkAllElementsLoaded();

    return () => {
      observer.disconnect();
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
    };
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const backgroundImage = isMobile ? '/BG_White_m.svg' : '/BG_white.svg';

  const LoadingSpinner = () => (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        backgroundImage: `url("${backgroundImage}")`, // Corregido aquí
        backgroundSize: 'cover', // Añadido para mejor ajuste
        backgroundPosition: 'center', // Añadido para centrar la imagen
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <img src="/loader_1.gif" alt="Loading..." />
    </div>
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!checkAccessToken()) {
          return <Redirect to={{ pathname: '/', state: { from: location } }} />;
        }
        return (
          <>
            {isLoading && <LoadingSpinner />}
            <div
              ref={componentRef}
              style={{ visibility: isLoading ? 'hidden' : 'visible' }}
            >
              <Component {...props} />
            </div>
          </>
        );
      }}
    />
  );
};

export default ProtectedRoute;
