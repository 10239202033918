import React, { useEffect, useState } from 'react';
import { domain_country, getDomain } from '../../config/domain';
import { ssoURL } from '../../config/environment';
import { generateCodeChallenge, generateState } from '../../modules/Auth/auth';
import { client_id } from '../../modules/services/sections';
import { gtmLogIn } from '../../tools/datalayers';

const LandingUserWithSession = () => {
  const [domain] = useState(
    domain_country(window.location.hostname + ''),
  );
  useEffect(() => {
    if (localStorage.getItem('code_verifier')) {
      window.location.href = '/landing';
    }
  });
  const login = () => {
    gtmLogIn()
    var codeChallenge = '';
    var state = generateState();

    generateCodeChallenge().then((res) => {
      codeChallenge = res;
      let args = new URLSearchParams({
        response_type: 'code',
        client_id: client_id,
        code_challenge_method: 'S256',
        code_challenge: codeChallenge,
        redirect_uri: `${getDomain(window.location.hostname + '').appURL
          }/auth/callback`,
        state: state,
        scope: 'openid',
      });

      localStorage.setItem('args', args.toString());
      localStorage.setItem(
        'appURL',
        getDomain(window.location.hostname + '').appURL?.toString() || '',
      );
      localStorage.setItem(
        'redirectSinSession',
        `${getDomain(window.location.hostname + '').appURL}/auth/callback`,
      );
      if (!window.localStorage.getItem('lastUrl'))
        window.localStorage.setItem('lastUrl', window.location.href);
      else {
      }
      window.location.href = `${ssoURL}/oauth/authorize?` + args.toString();
    });
  };

  return (
    <div className="withOut bg-black">
      <div className="row">
        <div className="col-lg-5 order-1 order-lg-1">
          <div className="imgLanding text-black">
            <img
              src={`${process.env.PUBLIC_URL}/customs/${domain.color}/without-image.png`}
              className="img-fluid"
              alt='Brewtech'
            />
          </div>
        </div>
        <div className="col-lg-7 order-2 order-lg-2 d-flex align-items-center">
          <div className="logSection">
            <div className="title-without">
              <h1 className="big-text fs-6">Bienvenidos</h1>
            </div>
            <button className="btn login-without btn-secondary" onClick={login}>
              Ingresar ahora
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingUserWithSession;
