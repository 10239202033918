export const landingId = 'HOM';
export const cultureId = 'CUL';
export const diversityId = 'DIV';
export const hubId = 'HUB';

export const wellnessId = 'WEL';
export const trademarksId = 'TRA';
export const profileId = 'PRO';
export const programans_eventsId = 'PYE';
export const tabDetailsId = 'FOC';

export const client_id = "93f7217d-bf85-470f-bc51-b8c6085030d6";
