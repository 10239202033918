import React, { useState, useEffect, useRef } from 'react';
import { getResult, FormEmailSubmit } from '../../../api/security/index';
import { useLocation } from 'react-router-dom';
import { StyledTitle, StyledSeccion } from './share.styles';
import {
  gtmSecurityShareButtonCopy,
  gtmSecurityShareSend,
  gtmSecurityShareSendError,
} from '../../../tools/datalayers';

interface Icon {
  url: string;
}

interface Image {
  url: string;
}

interface ItemForm {
  description: string;
  title: string;
  id: number;
}

interface ItemsStartForm {
  titleColor: string;
  titleSize: string;
  titleForm: string;
  foldsForm: {
    fieldType: string;
    message: string;
    submitEndPoint?: string;
    __component?: string;
    shareUrl?: string;
  }[];
  submitEndPoint?: string;
}

interface StartForm {
  itemForm: {
    itemsStartForm: ItemsStartForm;
  }[];
  __component?: string;
  submitEndPoint?: string;
  updatedAt?: string;
}

interface Fold {
  title: string;
  icontitle: Image;
  item: ItemForm[];
  backgroundColorItem: string;
  fontColorItemTitle: string;
  startForm?: StartForm;
}

interface BackgroundImgPage {
  url: string;
}

interface BackgroundImgPageMobile {
  url: string;
}

interface MenuItem {
  iconOff: Icon;
  iconOn: Icon;
  page: {
    slug: string;
  };
}

interface Content {
  backgroundPage: string;
  backgroundImgPage: BackgroundImgPage;
  backgroundImgPageMobile: BackgroundImgPageMobile;
  folds: Fold[];
  menu: MenuItem[];
  endpoint: string;
}

interface ResultData {
  content: Content[];
}

export const Share: React.FC = () => {
  const [resultData, setFetchedData] = useState<ResultData | null>(null);
  const [menu, setMenu] = useState<MenuItem[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [email, setName] = useState('');
  const [share, setShare] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(true); // Añade esta línea
  const [endpointFull, setEndpoint] = useState('');

  const [isRedirecting, setIsRedirecting] = useState(false);

  const baseApi = process.env.REACT_APP_API_BASE_URL;
  const base = process.env.REACT_APP_APP_URL;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('myAccessToken') || 'myAccessToken';
        const endpointResult = 'securitymatch/referidos-form';
        const { data } = await getResult(token, endpointResult);

        setFetchedData(data);
        setMenu(data?.content[0]?.menu);
        setShare(
          data?.content[0]?.folds[0]?.startForm?.itemForm[0]?.itemsStartForm
            ?.foldsForm[0]?.shareUrl ?? '',
        );
        setEndpoint(
          data?.content[0]?.folds[0]?.startForm?.submitEndPoint ?? '',
        );
      } catch (error) {
        console.error('Error de servicio', error);
        setError('Ocurrió un error al cargar los datos');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCopy = async () => {
    const Pathfull = window.location.origin + '/securitymatch';
    const combinedData = `${Pathfull}${share}`;
    try {
      await navigator.clipboard.writeText(combinedData);
      alert('Texto copiado al portapapeles');
    } catch (err) {
      console.error('Error al copiar el texto: ', err);
    }
    gtmSecurityShareButtonCopy();
  };

  const handleEmailSubmit = async (
    endpoint: string,
    inputValue: string,
    component: string,
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      const tokenJwt = localStorage.getItem('myAccessToken') || 'undefined';
      const { data } = await FormEmailSubmit(
        endpoint,
        tokenJwt,
        inputValue,
        component,
      );

      if (data.next) {
        await handleNext(endpointFull, inputValue, component);
      } else {
        setIsLoading(false);
      }
      gtmSecurityShareSend();
    } catch (error: any) {
      console.error('Error en handleEmailSubmit:', error);
      const message =
        error.response?.data?.error?.message || 'Error en el servicio';
      setError(message);
      setIsLoading(false);
      gtmSecurityShareSendError();
    }
  };

  const handleNext = async (
    endpoint: string,
    inputValue: string,
    component: string,
  ) => {
    try {
      const tokenJwt = localStorage.getItem('myAccessToken') || 'undefined';
      const { data } = await FormEmailSubmit(
        endpoint,
        tokenJwt,
        inputValue,
        component,
      );

      if (data?.endpoint) {
        setIsRedirecting(true);
        const fullUrl = `${window.location.origin}/${data.endpoint}`;
        window.location.href = fullUrl; // Usar window.location.href para una redirección completa
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error en handleNext:', error);
      setError('Error al procesar la solicitud');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const backgroundImage = isMobile ? '/BG_White_m.svg' : '/BG_white.svg';

  const LoadingSpinner = () => (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        backgroundImage: `url("${backgroundImage}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <img src="/loader_1.gif" alt="Loading..." />
    </div>
  );

  const currentPath = window.location.pathname.replace(/^\/+/, '');

  if (isLoading || isRedirecting) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <StyledSeccion
        className="page share"
        bgColor={resultData?.content[0]?.backgroundPage ?? 'fff'}
      >
        <picture className="content__bg">
          <source
            srcSet={`${baseApi}${resultData?.content[0]?.backgroundImgPage?.url}`}
            media="(min-width: 780px)"
          />
          <img
            className="ranking__img"
            src={`${baseApi}${resultData?.content[0]?.backgroundImgPageMobile?.url}`}
          />
        </picture>
        <div className="content__form-container">
          <div className="share__content-text">
            <img
              className="share__img"
              src={`${base}/security/icon-share-title.svg`}
              alt=""
            />
            <StyledTitle
              className="share__title"
              color={
                resultData?.content[0]?.folds[0]?.startForm?.itemForm[0]
                  ?.itemsStartForm?.titleColor
              }
              size={
                resultData?.content[0]?.folds[0]?.startForm?.itemForm[0]
                  ?.itemsStartForm?.titleSize
              }
            >
              {
                resultData?.content[0]?.folds[0]?.startForm?.itemForm[0]
                  ?.itemsStartForm?.titleForm
              }
            </StyledTitle>
          </div>

          <div className={`content__input ${error ? 'error-input' : ''}`}>
            <label htmlFor="emailInput" className="share__label">
              ¿A quién se lo quieres enviar?
            </label>
            <input
              type={
                resultData?.content[0]?.folds[0]?.startForm?.itemForm[0]
                  ?.itemsStartForm?.foldsForm[0]?.fieldType
              }
              value={email}
              onChange={(e) => setName(e.target.value)}
              placeholder="Ej. nombre@mail.com"
            />
            {error && <p className="error-form">{error}</p>}
          </div>
          <div className="content_button-share">
            {email === '' ? (
              <button onClick={handleCopy} className="buttom-share-one">
                Copiar URL
              </button>
            ) : (
              <button
                style={{
                  backgroundColor:
                    resultData?.content[0]?.folds[0]?.backgroundColorItem,
                }}
                onClick={() => {
                  const submitEndPoint =
                    resultData?.content[0]?.folds[0]?.startForm?.itemForm[0]
                      ?.itemsStartForm?.submitEndPoint ?? '';
                  const component =
                    resultData?.content[0]?.folds[0]?.startForm?.itemForm[0]
                      ?.itemsStartForm?.foldsForm[0]?.__component ?? '';
                  handleEmailSubmit(submitEndPoint, email, component);
                }}
                className="button-share-two"
              >
                Enviar invitación
              </button>
            )}
          </div>
          <p
            className={`alert__message ${error ? 'error-alert' : ''}`}
            dangerouslySetInnerHTML={{
              __html:
                resultData?.content[0]?.folds[0]?.startForm?.itemForm[0]
                  ?.itemsStartForm?.foldsForm[0]?.message ?? '',
            }}
          ></p>
        </div>
        {menu && (
          <div className="menu">
            <div className="menu__container">
              <div className="menu__content">
                {Array.isArray(menu) &&
                  menu?.map((item, index) => (
                    <div key={index}>
                      <img
                        src={`${baseApi}${
                          currentPath === item?.page?.slug
                            ? item?.iconOn?.url
                            : item?.iconOff?.url
                        }`}
                        alt={item?.page?.slug}
                        className={`item-avatars-img ${
                          currentPath === item?.page?.slug ? 'active' : ''
                        }`}
                        onClick={() => {
                          if (item?.page?.slug) {
                            const fullUrl = `${window.location.origin}/${item?.page?.slug}`;
                            window.open(fullUrl, '_self');
                          }
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </StyledSeccion>
    </>
  );
};
