import styled from 'styled-components';

interface StyledProps {
  bgColor?: string;
  backgroundImage?: string;
}

export const StyledSeccion = styled.div<StyledProps>`
  background-color: ${(props) => props?.bgColor ?? "#fff"};
`;

export const StyledSectionContent = styled.div<StyledProps>`
  background-image: url(${(props) => props?.backgroundImage});
`;


